import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NewsStateModel } from './news.model';
import { NEWS_STATE } from '..';
import { Injectable } from '@angular/core';
import { NewsService } from 'src/app/shared/services/news.service';
import { News } from './news.action';
import { tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { UpdateNewsInput } from 'src/app/shared/graphql/generated/graphql';

@State<NewsStateModel>({
  name: NEWS_STATE,
  defaults: {
    newsList: [],
    selectedNews: null,
    recentNews: null,
    fetching: false,
  },
})
@Injectable()
export class NewsState {
  constructor(
    private router: Router,
    private newsService: NewsService,
    private messageService: MessageService,
  ) {}

  @Selector()
  static newsList(state: NewsStateModel) {
    return state.newsList;
  }

  @Selector()
  static newsById(state: NewsStateModel) {
    return (newsId: number | null) => state.newsList.find((news) => news.id === newsId)
  }

  @Selector()
  static selectedNews(state: NewsStateModel) {
    return state.selectedNews;
  }

  @Selector()
  static recentNews(state: NewsStateModel) {
    return state.recentNews;
  }

  @Selector()
  static fetching(state: NewsStateModel) {
    return state.fetching;
  }

  @Action(News.GetNewsList)
  loadNewsList({ patchState }: StateContext<NewsStateModel>) {
    patchState({ fetching: true });
    return this.newsService.getNewsList().pipe(
      tap((newsList) => {
        patchState({
          fetching: false,
          newsList,
        });
      })
    );
  }

  @Action(News.SelectNewsById)
  selectNews({ patchState, getState }: StateContext<NewsStateModel>, action: News.SelectNewsById) {
    const state = getState();
    const selectedNews = state.newsList.find((news) => news.id === action.id);

    patchState({
      fetching: false,
      selectedNews
    });
  }

  @Action(News.UnsetSelectedNews)
  unsetSelectedNews({ patchState, getState }: StateContext<NewsStateModel>) {
    const state = getState();

    patchState({
      fetching: false,
      selectedNews: null
    });
  }

  @Action(News.UnsetRecentNews)
  unsetRecentNews({ patchState, getState }: StateContext<NewsStateModel>) {
    patchState({
      recentNews: null
    });
  }

  @Action(News.CreateNews)
  createNews(ctx: StateContext<NewsStateModel>, action: News.CreateNews) {
    return this.newsService.createNews(action.createNewsInput).pipe(
      tap((recentNews) => {
        const state = ctx.getState()

        ctx.patchState({
          newsList: [...state.newsList!, recentNews],
          recentNews
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'News created successfully.'
        })
      })
    )
  }

  @Action(News.UpdateNews)
  updateNews(ctx: StateContext<NewsStateModel>, action: News.UpdateNews) {
    return this.newsService.updateNews(action.updateNewsInput).pipe(
      tap((updatedNews) => {
        const state = ctx.getState()

        ctx.patchState({
          newsList: state.newsList!.map((news) => news.id === updatedNews.id ? updatedNews : news)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'News updated successfully.'
        })
      })
    )
  }

  @Action(News.ToggleActive)
  toggleActive(ctx: StateContext<NewsStateModel>, action: News.ToggleActive) {
    const news = ctx.getState().newsList.find((news) => news.id === Number(action.id))!;

    const payload: UpdateNewsInput = {
      id: news.id,
      visible: !news.visible
    }

    return this.newsService.updateNews(payload).pipe(
      tap((updatedNews) => {
        const state = ctx.getState()

        ctx.patchState({
          newsList: state.newsList!.map((news) => news.id === updatedNews.id ? updatedNews : news),
          selectedNews: state.selectedNews ? updatedNews : null
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${news?.title} is now ${payload.visible ? 'enabled' : 'disabled'}.`
        })
      })
    )
  }

  @Action(News.RemoveNews)
  removeNews(ctx: StateContext<NewsStateModel>, action: News.RemoveNews) {
    return this.newsService.removeNews(action.newsId).pipe(
      tap({
        next: (removedNews) => {
          const state = ctx.getState()

          ctx.patchState({
            newsList: state.newsList!.filter((news) => news.id !== removedNews.id),
            selectedNews: state.selectedNews?.id === removedNews.id ? null : state.selectedNews
          })

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'News deleted successfully.'
          });

          this.router.navigateByUrl('/administration/news')
        },
        error: (err) => {
          console.error(err)
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Delete news failed.'
          })
        }
      })
    )
  }
}
