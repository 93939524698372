import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AppSettings = {
  __typename?: 'AppSettings';
  isLoginDisabled: Scalars['Boolean'];
  loginDisabledMessage?: Maybe<Scalars['String']>;
  loginDisabledMessageType: SystemInfoMessageType;
  preLoginMessage?: Maybe<Scalars['String']>;
  preLoginMessageType: SystemInfoMessageType;
  showPreLoginMessage: Scalars['Boolean'];
  showSystemMessage: Scalars['Boolean'];
  systemMessage?: Maybe<Scalars['String']>;
  systemMessageType: SystemInfoMessageType;
};

export type CreateNewsCategoryInput = {
  color: Scalars['String'];
  name: Scalars['String'];
};

export type CreateNewsInput = {
  newsCategoryId?: InputMaybe<Scalars['Int']>;
  sideboxEnabled?: InputMaybe<Scalars['Boolean']>;
  sideboxText?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrganisationInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  house_no?: InputMaybe<Scalars['String']>;
  isDomainRestrictionEnabled: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isTrial: Scalars['Boolean'];
  licenseExpiresAt?: InputMaybe<Scalars['DateTime']>;
  licenseNo?: InputMaybe<Scalars['String']>;
  licenseRenews: Scalars['Boolean'];
  licenseType: LicenseType;
  linkedOrganisationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permittedDomainsPattern?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street_additional?: InputMaybe<Scalars['String']>;
  trialDays?: InputMaybe<Scalars['Int']>;
  userAmount?: InputMaybe<Scalars['Int']>;
  userSessionLimit?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CreateProductInput = {
  description: Scalars['String'];
  icon?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateSpecialAnalysisInput = {
  description: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  productId: Scalars['Int'];
};

export type CreateUserForOwnOrgInput = {
  department?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  isOrgAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  salutation: Scalars['String'];
  sendActivationMail?: InputMaybe<Scalars['Boolean']>;
  sendActivationMailCopyToOwnEmail?: InputMaybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  comment?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  isOrgAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  organisationId: Scalars['String'];
  passwordV2?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salutation: Scalars['String'];
  sendActivationMail?: InputMaybe<Scalars['Boolean']>;
  sendActivationMailCopyToOwnEmail?: InputMaybe<Scalars['Boolean']>;
};

export enum CustomErrorCode {
  /** the provided token is either expired or incorrect */
  AuthIncorrectPasswordChange = 'AUTH_INCORRECT_PASSWORD_CHANGE',
  /** provided email or passwords do not match our db */
  AuthLoginInvalidEmailOrPassword = 'AUTH_LOGIN_INVALID_EMAIL_OR_PASSWORD',
  /** the token to reset password has expired */
  AuthTokenExpired = 'AUTH_TOKEN_EXPIRED',
  /** the provided token is either expired or incorrect */
  AuthTokenInvalid = 'AUTH_TOKEN_INVALID',
  BadRequest = 'BAD_REQUEST',
  /** user without an org is trying to access the dataset */
  DatasetAccessDenied = 'DATASET_ACCESS_DENIED',
  /** dataset does not exists in app db */
  DatasetNotFound = 'DATASET_NOT_FOUND',
  /** dataset does not exists in data db */
  DatasetNotInDb = 'DATASET_NOT_IN_DB',
  /** user is trying to access a dataset to a product that they don't own */
  DatasetProductAccessDenied = 'DATASET_PRODUCT_ACCESS_DENIED',
  /** user is trying to access dataset to a sp analysis that they don't own */
  DatasetSpAnalysisAccessDenied = 'DATASET_SP_ANALYSIS_ACCESS_DENIED',
  /** the specified entity already exists in the system and cannot be duplicated */
  EntityAlreadyExists = 'ENTITY_ALREADY_EXISTS',
  /** the specified entity could not be found in the system db */
  EntityNotFound = 'ENTITY_NOT_FOUND',
  FilterSettingAddInvalidProduct = 'FILTER_SETTING_ADD_INVALID_PRODUCT',
  /** the user already created a filter setting for the selected product */
  FilterSettingAlreadyExists = 'FILTER_SETTING_ALREADY_EXISTS',
  /** the filter setting does not exist in the db */
  FilterSettingNotFound = 'FILTER_SETTING_NOT_FOUND',
  /** user is trying to update a filter setting that belongs to another product */
  FilterSettingProductMismatch = 'FILTER_SETTING_PRODUCT_MISMATCH',
  FilterSettingUnauthorizedUpdate = 'FILTER_SETTING_UNAUTHORIZED_UPDATE',
  /** user is trying to perform an operation without a valid access level */
  InsufficientAccessLevel = 'INSUFFICIENT_ACCESS_LEVEL',
  /** user is trying to create another user without being an org admin */
  InsufficientOrgAccessLevel = 'INSUFFICIENT_ORG_ACCESS_LEVEL',
  /** unhandled exception or error encountered on the server */
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  /** the specified news category could not be found in the system db  */
  NewsCategoryNotFound = 'NEWS_CATEGORY_NOT_FOUND',
  /** the specified news could not be found in the system db  */
  NewsNotFound = 'NEWS_NOT_FOUND',
  /** id is not provided when querying for data */
  NoEntityIdProvided = 'NO_ENTITY_ID_PROVIDED',
  /** org admin is trying to delete users outside of their own organisation */
  OrgAdminDeleteOtherOrgUserAttempt = 'ORG_ADMIN_DELETE_OTHER_ORG_USER_ATTEMPT',
  /** trying to add a product that the org already owns */
  OrgAlreadyOwnsProduct = 'ORG_ALREADY_OWNS_PRODUCT',
  /** trying to add a sp analysis that the org already owns */
  OrgAlreadyOwnsSpAnalysis = 'ORG_ALREADY_OWNS_SP_ANALYSIS',
  /** trying to add sp analysis to an org that doesn't own the product the sp analysis belongs to */
  OrgAnalysisAddWithoutOwningProduct = 'ORG_ANALYSIS_ADD_WITHOUT_OWNING_PRODUCT',
  /** the organisation does not exist in the db */
  OrgNotFound = 'ORG_NOT_FOUND',
  /** the product does not exist in the db */
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  /** too many requests in a short period of time */
  RateLimitExceeded = 'RATE_LIMIT_EXCEEDED',
  /** the sp analysis does not exist in the db */
  SpecialAnalysisNotFound = 'SPECIAL_ANALYSIS_NOT_FOUND',
  /** attempt to access a resource or data without the necessary permissions or authentication credentials. */
  UnauthorizedAccess = 'UNAUTHORIZED_ACCESS',
  /** operation attempted without required permissions or outside the scope of the authenticated user. */
  UnauthorizedOperation = 'UNAUTHORIZED_OPERATION',
  /** attempt to create a user without the necessary permissions or authentication credentials. */
  UnauthorizedUserCreate = 'UNAUTHORIZED_USER_CREATE',
  /** attempt to delete a user without the necessary permissions or authentication credentials. */
  UnauthorizedUserDelete = 'UNAUTHORIZED_USER_DELETE',
  /** unlisted or unknown error */
  UnlistedError = 'UNLISTED_ERROR',
  /** locked user trying to authenticate/access the app */
  UserAccountLocked = 'USER_ACCOUNT_LOCKED',
  /** not yet activated user trying to authenticate/access the app */
  UserAccountNotActivated = 'USER_ACCOUNT_NOT_ACTIVATED',
  /** trying to activate or send an email to an already activated user */
  UserAlreadyActivated = 'USER_ALREADY_ACTIVATED',
  /** the current user is trying to delete their own account directly */
  UserDeleteOwnAccountAttempt = 'USER_DELETE_OWN_ACCOUNT_ATTEMPT',
  /** the user does not exist in the db */
  UserNotFound = 'USER_NOT_FOUND',
  /** user is trying to access organisation data without being part of the organisation/not an admin */
  UserNotInCurrentOrg = 'USER_NOT_IN_CURRENT_ORG',
  /** user without organization is trying to authenticate/access the app */
  UserNoOrganization = 'USER_NO_ORGANIZATION',
  /** user is trying to create/update a filter setting for a product they don't own */
  UserOrgDoesNotOwnProduct = 'USER_ORG_DOES_NOT_OWN_PRODUCT',
  UserOrgDoesNotOwnSpAnalysis = 'USER_ORG_DOES_NOT_OWN_SP_ANALYSIS',
  /** user in org with expired license is trying to authenticate/access the app */
  UserOrgLicenseExpired = 'USER_ORG_LICENSE_EXPIRED',
  /** user in locked org is trying to authenticate/access the app */
  UserOrgLocked = 'USER_ORG_LOCKED',
  /** user in org with expired trial is trying to authenticate/access the app */
  UserOrgTrialExpired = 'USER_ORG_TRIAL_EXPIRED'
}

export type DatasetProductSpecialAnalysis = {
  __typename?: 'DatasetProductSpecialAnalysis';
  datasetId: Scalars['String'];
  product: Product;
  productId: Scalars['Int'];
  specialAnalysis: Product;
  specialAnalysisId?: Maybe<Scalars['Int']>;
};

export type FilterSetting = {
  __typename?: 'FilterSetting';
  districtId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  methodologyId?: Maybe<Scalars['Int']>;
  product: Product;
  productId: Scalars['Int'];
  ranking?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['Int']>;
  submarketId?: Maybe<Scalars['Int']>;
  user: User;
  userId: Scalars['String'];
  year?: Maybe<Scalars['String']>;
};

export type FindAllOrganisationChanges = {
  __typename?: 'FindAllOrganisationChanges';
  items: Array<OrganisationChange>;
  totalCount: Scalars['Int'];
};

export type FindAllOrganisationEvents = {
  __typename?: 'FindAllOrganisationEvents';
  items: Array<OrganisationEvent>;
  totalCount: Scalars['Int'];
};

export type FindAllUserChanges = {
  __typename?: 'FindAllUserChanges';
  items: Array<UserChange>;
  totalCount: Scalars['Int'];
};

export type FindAllUserEvents = {
  __typename?: 'FindAllUserEvents';
  items: Array<UserEvent>;
  totalCount: Scalars['Int'];
};

export type FindAndCountAllParams = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['String']>;
};

export type FindAndCountChangesParams = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['String']>;
};

export type FindAndCountEventsParams = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['String']>;
};

export enum LicenseType {
  Main = 'main',
  Sub = 'sub'
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: UserSelf;
};

export type Mutation = {
  __typename?: 'Mutation';
  appSettingsUpdate: AppSettings;
  authActivateUser: LoginResponse;
  authChangePassword: Scalars['Boolean'];
  authLogin: LoginResponse;
  authRefreshToken: TokenPair;
  authResetPassword: LoginResponse;
  authResetPasswordRequest: Scalars['String'];
  authSetUserPassword: Scalars['Boolean'];
  filterSettingSet: FilterSetting;
  newsCategoryCreate: NewsCategory;
  newsCategoryRemove: NewsCategory;
  newsCategoryUpdate: NewsCategory;
  newsCreate: News;
  newsRemove: News;
  newsUpdate: News;
  organisationAddProduct: Organisation;
  organisationAddSpecialAnalysis: Organisation;
  organisationCreate: Organisation;
  organisationRemove: Organisation;
  organisationRemoveProduct: Organisation;
  organisationRemoveSpecialAnalysis: Organisation;
  organisationUpdate: Organisation;
  organisationUpdateOwn: Organisation;
  productCreate: Product;
  productRemove: Product;
  productUpdate: Product;
  specialAnalysisCreate: SpecialAnalysis;
  specialAnalysisRemove: SpecialAnalysis;
  specialAnalysisUpdate: SpecialAnalysis;
  userCreate: User;
  userCreateForOwnOrganisation: User;
  userRemove: User;
  userResendActivationEmail: Scalars['Boolean'];
  userUpdate: User;
  userUpdateSelf: UserSelf;
};


export type MutationAppSettingsUpdateArgs = {
  updateAppSettingInput: UpdateAppSettingInput;
};


export type MutationAuthActivateUserArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationAuthChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationAuthLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAuthResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationAuthResetPasswordRequestArgs = {
  email: Scalars['String'];
};


export type MutationAuthSetUserPasswordArgs = {
  newPassword: Scalars['String'];
  repeatPassword: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationFilterSettingSetArgs = {
  setFilterSettingInput: SetFilterSettingInput;
};


export type MutationNewsCategoryCreateArgs = {
  newsCategoryCreateInput: CreateNewsCategoryInput;
};


export type MutationNewsCategoryRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationNewsCategoryUpdateArgs = {
  newsCategoryUpdateInput: UpdateNewsCategoryInput;
};


export type MutationNewsCreateArgs = {
  newsCreateInput: CreateNewsInput;
};


export type MutationNewsRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationNewsUpdateArgs = {
  newsUpdateInput: UpdateNewsInput;
};


export type MutationOrganisationAddProductArgs = {
  organisationId: Scalars['String'];
  productId: Scalars['Float'];
};


export type MutationOrganisationAddSpecialAnalysisArgs = {
  organisationId: Scalars['String'];
  specialAnalysisId: Scalars['Float'];
};


export type MutationOrganisationCreateArgs = {
  organisationCreateInput: CreateOrganisationInput;
};


export type MutationOrganisationRemoveArgs = {
  id: Scalars['String'];
};


export type MutationOrganisationRemoveProductArgs = {
  organisationId: Scalars['String'];
  productId: Scalars['Float'];
};


export type MutationOrganisationRemoveSpecialAnalysisArgs = {
  organisationId: Scalars['String'];
  specialAnalysisId: Scalars['Float'];
};


export type MutationOrganisationUpdateArgs = {
  organisationUpdateInput: UpdateOrganisationInput;
};


export type MutationOrganisationUpdateOwnArgs = {
  organisationEditOwnInput: OrganisationEditOwnInput;
};


export type MutationProductCreateArgs = {
  productCreateInput: CreateProductInput;
};


export type MutationProductRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationProductUpdateArgs = {
  productUpdateInput: UpdateProductInput;
};


export type MutationSpecialAnalysisCreateArgs = {
  createSpecialAnalysisInput: CreateSpecialAnalysisInput;
};


export type MutationSpecialAnalysisRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationSpecialAnalysisUpdateArgs = {
  updateSpecialAnalysisInput: UpdateSpecialAnalysisInput;
};


export type MutationUserCreateArgs = {
  userCreateInput: CreateUserInput;
};


export type MutationUserCreateForOwnOrganisationArgs = {
  userCreateForOwnOrgInput: CreateUserForOwnOrgInput;
};


export type MutationUserRemoveArgs = {
  id: Scalars['String'];
};


export type MutationUserResendActivationEmailArgs = {
  resendActivationEmailInput: ResendUserActivationEmailInput;
};


export type MutationUserUpdateArgs = {
  userUpdateInput: UpdateUserInput;
};


export type MutationUserUpdateSelfArgs = {
  userUpdateSelfInput: UpdateSelfInput;
};

export type News = {
  __typename?: 'News';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  newsCategory?: Maybe<NewsCategory>;
  newsCategoryId?: Maybe<Scalars['Int']>;
  sideboxEnabled?: Maybe<Scalars['Boolean']>;
  sideboxText?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
  visible?: Maybe<Scalars['Boolean']>;
};

export type NewsCategory = {
  __typename?: 'NewsCategory';
  color: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  news?: Maybe<Array<Maybe<News>>>;
};

export type Organisation = {
  __typename?: 'Organisation';
  abbreviation?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  house_no?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDomainRestrictionEnabled: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isTrial: Scalars['Boolean'];
  licenseExpiresAt?: Maybe<Scalars['DateTime']>;
  licenseNo?: Maybe<Scalars['String']>;
  licenseRenews: Scalars['Boolean'];
  licenseType: LicenseType;
  linkedOrganisation?: Maybe<Organisation>;
  linkedOrganisationId?: Maybe<Scalars['String']>;
  linkedOrganisations: Array<Maybe<Organisation>>;
  name: Scalars['String'];
  organisationEvents?: Maybe<Array<Maybe<OrganisationEvent>>>;
  permittedDomainsPattern?: Maybe<Scalars['String']>;
  products: Array<Maybe<Product>>;
  specialAnalyses: Array<Maybe<SpecialAnalysis>>;
  status: OrganisationStatus;
  street?: Maybe<Scalars['String']>;
  street_additional?: Maybe<Scalars['String']>;
  trialActivatedAt?: Maybe<Scalars['DateTime']>;
  trialDays: Scalars['Int'];
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  userAmount?: Maybe<Scalars['Int']>;
  userSessionLimit?: Maybe<Scalars['Int']>;
  users: Array<Maybe<User>>;
  zip?: Maybe<Scalars['String']>;
};

export type OrganisationChange = {
  __typename?: 'OrganisationChange';
  columnName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  event?: Maybe<OrganisationEvent>;
  eventId: Scalars['String'];
  id: Scalars['Int'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  organisationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganisationEditOwnInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  house_no?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street_additional?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type OrganisationEvent = {
  __typename?: 'OrganisationEvent';
  createdAt: Scalars['DateTime'];
  eventType: OrganisationEventType;
  id: Scalars['String'];
  organisation?: Maybe<Organisation>;
  organisationChanges?: Maybe<Array<Maybe<OrganisationChange>>>;
  organisationId?: Maybe<Scalars['String']>;
  performedBy: User;
  performedById: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum OrganisationEventType {
  AddressUpdated = 'address_updated',
  Created = 'created',
  Deleted = 'deleted',
  ExpiresAtChanged = 'expires_at_changed',
  LicenseTypeChanged = 'license_type_changed',
  Locked = 'locked',
  ProductAdded = 'product_added',
  ProductRemoved = 'product_removed',
  SpAnalysisAdded = 'sp_analysis_added',
  SpAnalysisRemoved = 'sp_analysis_removed',
  StatusChanged = 'status_changed',
  TrialSettingsChanged = 'trial_settings_changed',
  Unlocked = 'unlocked',
  Updated = 'updated',
  UserAdded = 'user_added',
  UserRemoved = 'user_removed'
}

export enum OrganisationStatus {
  Active = 'ACTIVE',
  LicenseExpired = 'LICENSE_EXPIRED',
  LicenseExpiring = 'LICENSE_EXPIRING',
  LicenseRenewing = 'LICENSE_RENEWING',
  Locked = 'LOCKED',
  NotActivated = 'NOT_ACTIVATED',
  TrialActive = 'TRIAL_ACTIVE',
  TrialExpired = 'TRIAL_EXPIRED',
  TrialNotActivated = 'TRIAL_NOT_ACTIVATED'
}

export type Product = {
  __typename?: 'Product';
  datasetProductSpecialAnalyses: Array<DatasetProductSpecialAnalysis>;
  defaultMethodologyId?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  organisations?: Maybe<Array<Organisation>>;
  productMethodologies: Array<Maybe<ProductMethodologies>>;
  specialAnalyses: Array<SpecialAnalysis>;
};

export type ProductMethodologies = {
  __typename?: 'ProductMethodologies';
  methodologyId: Scalars['Int'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  appSettings: AppSettings;
  authGetResetPasswordLink: Scalars['String'];
  authUserActivationToken: Scalars['String'];
  errorCodes: Array<CustomErrorCode>;
  filterSettingsBelongToUser: Array<FilterSetting>;
  me: UserSelf;
  news: News;
  newsCategories: Array<NewsCategory>;
  newsCategory: NewsCategory;
  newsList: Array<News>;
  organisation: Organisation;
  organisationChanges: FindAllOrganisationChanges;
  organisationEvents: FindAllOrganisationEvents;
  organisations: Array<Organisation>;
  product: Product;
  products: Array<Product>;
  specialAnalyses: Array<SpecialAnalysis>;
  specialAnalysis: SpecialAnalysis;
  user: User;
  userActivationLink: Scalars['String'];
  userChanges: FindAllUserChanges;
  userEvents: FindAllUserEvents;
  users: Array<User>;
};


export type QueryAuthGetResetPasswordLinkArgs = {
  userId: Scalars['String'];
};


export type QueryAuthUserActivationTokenArgs = {
  userId: Scalars['String'];
};


export type QueryNewsArgs = {
  id: Scalars['Int'];
};


export type QueryNewsCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryOrganisationArgs = {
  id: Scalars['String'];
};


export type QueryOrganisationChangesArgs = {
  orgChangesParams?: InputMaybe<FindAndCountAllParams>;
};


export type QueryOrganisationEventsArgs = {
  orgEventsParams?: InputMaybe<FindAndCountAllParams>;
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QuerySpecialAnalysisArgs = {
  id: Scalars['Int'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserActivationLinkArgs = {
  userId: Scalars['String'];
};


export type QueryUserChangesArgs = {
  userChangesParams?: InputMaybe<FindAndCountChangesParams>;
};


export type QueryUserEventsArgs = {
  userEventsParams?: InputMaybe<FindAndCountEventsParams>;
};

export type ResendUserActivationEmailInput = {
  id: Scalars['String'];
  sendActivationMail?: InputMaybe<Scalars['Boolean']>;
  sendActivationMailCopyToOwnEmail?: InputMaybe<Scalars['Boolean']>;
};

export type SetFilterSettingInput = {
  districtId?: InputMaybe<Scalars['Int']>;
  methodologyId?: InputMaybe<Scalars['Int']>;
  productId: Scalars['Int'];
  ranking?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['Int']>;
  submarketId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['String']>;
};

export type SpecialAnalysis = {
  __typename?: 'SpecialAnalysis';
  datasetProductSpecialAnalyses: Array<DatasetProductSpecialAnalysis>;
  description: Scalars['String'];
  id: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  organisations?: Maybe<Array<Organisation>>;
  product: Product;
  productId: Scalars['Int'];
};

export enum SystemInfoMessageType {
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning'
}

export type TokenPair = {
  __typename?: 'TokenPair';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type UpdateAppSettingInput = {
  isLoginDisabled?: InputMaybe<Scalars['Boolean']>;
  loginDisabledMessage?: InputMaybe<Scalars['String']>;
  loginDisabledMessageType?: InputMaybe<SystemInfoMessageType>;
  preLoginMessage?: InputMaybe<Scalars['String']>;
  preLoginMessageType?: InputMaybe<SystemInfoMessageType>;
  showPreLoginMessage?: InputMaybe<Scalars['Boolean']>;
  showSystemMessage?: InputMaybe<Scalars['Boolean']>;
  systemMessage?: InputMaybe<Scalars['String']>;
  systemMessageType?: InputMaybe<SystemInfoMessageType>;
};

export type UpdateNewsCategoryInput = {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateNewsInput = {
  id: Scalars['Int'];
  newsCategoryId?: InputMaybe<Scalars['Int']>;
  sideboxEnabled?: InputMaybe<Scalars['Boolean']>;
  sideboxText?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateOrganisationInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  house_no?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isDomainRestrictionEnabled?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isTrial?: InputMaybe<Scalars['Boolean']>;
  licenseExpiresAt?: InputMaybe<Scalars['DateTime']>;
  licenseNo?: InputMaybe<Scalars['String']>;
  licenseRenews?: InputMaybe<Scalars['Boolean']>;
  licenseType?: InputMaybe<LicenseType>;
  linkedOrganisationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permittedDomainsPattern?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street_additional?: InputMaybe<Scalars['String']>;
  trialActivatedAt?: InputMaybe<Scalars['DateTime']>;
  trialDays?: InputMaybe<Scalars['Int']>;
  trialExpiresAt?: InputMaybe<Scalars['DateTime']>;
  userAmount?: InputMaybe<Scalars['Int']>;
  userSessionLimit?: InputMaybe<Scalars['Int']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpdateProductInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateSelfInput = {
  department?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateSpecialAnalysisInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserInput = {
  comment?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isActivated?: InputMaybe<Scalars['Boolean']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isOrgAdmin?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['String']>;
  passwordV2?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  sendActivationMail?: InputMaybe<Scalars['Boolean']>;
  sendActivationMailCopyToOwnEmail?: InputMaybe<Scalars['Boolean']>;
  sessionLimit?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  activatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  filterSettings: Array<FilterSetting>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  isActivated: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isOrgAdmin: Scalars['Boolean'];
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  organisation?: Maybe<Organisation>;
  organisationEvents?: Maybe<Array<Maybe<OrganisationEvent>>>;
  organisationId: Scalars['String'];
  passwordChangedAt?: Maybe<Scalars['DateTime']>;
  phone?: Maybe<Scalars['String']>;
  salutation: Scalars['String'];
  sessionLimit?: Maybe<Scalars['Int']>;
  status: UserStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userEvents?: Maybe<Array<Maybe<UserEvent>>>;
  userOwnEvents?: Maybe<Array<Maybe<UserEvent>>>;
};

export type UserChange = {
  __typename?: 'UserChange';
  columnName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  event?: Maybe<UserEvent>;
  eventId: Scalars['String'];
  id: Scalars['Float'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type UserEvent = {
  __typename?: 'UserEvent';
  createdAt: Scalars['DateTime'];
  eventType: UserEventType;
  id: Scalars['String'];
  performedBy: User;
  performedById: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userChanges?: Maybe<Array<Maybe<UserChange>>>;
  userId?: Maybe<Scalars['String']>;
};

export enum UserEventType {
  AccessLevelChanged = 'access_level_changed',
  ActivationChanged = 'activation_changed',
  ActivationEmailSent = 'activation_email_sent',
  ActivationLinkGenerated = 'activation_link_generated',
  Created = 'created',
  Deleted = 'deleted',
  PasswordChanged = 'password_changed',
  ProfileChanged = 'profile_changed',
  ReferencedOrganisationChanged = 'referenced_organisation_changed',
  RequestPasswordChange = 'request_password_change',
  RequestPasswordResetLink = 'request_password_reset_link',
  StatusChanged = 'status_changed',
  Updated = 'updated'
}

export type UserSelf = {
  __typename?: 'UserSelf';
  accessible_datasets?: Maybe<Scalars['JSON']>;
  claims?: Maybe<Array<Scalars['String']>>;
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isActivated: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isOrgAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  organisation?: Maybe<Organisation>;
  organisationId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  salutation: Scalars['String'];
};

export enum UserStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  LicenseExpired = 'LICENSE_EXPIRED',
  Locked = 'LOCKED',
  NotActivated = 'NOT_ACTIVATED',
  NotInOrganisation = 'NOT_IN_ORGANISATION',
  TrialActive = 'TRIAL_ACTIVE',
  TrialExpired = 'TRIAL_EXPIRED'
}

export type AuthActivateUserMutationVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
}>;


export type AuthActivateUserMutation = { __typename?: 'Mutation', authActivateUser: { __typename?: 'LoginResponse', accessToken: string, refreshToken: string, user: { __typename?: 'UserSelf', id: string } } };

export type AuthLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthLoginMutation = { __typename?: 'Mutation', authLogin: { __typename?: 'LoginResponse', accessToken: string, refreshToken: string, user: { __typename?: 'UserSelf', id: string, email: string, firstName: string, lastName: string } } };

export type AuthRefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type AuthRefreshTokenMutation = { __typename?: 'Mutation', authRefreshToken: { __typename?: 'TokenPair', accessToken: string, refreshToken: string } };

export type AuthChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type AuthChangePasswordMutation = { __typename?: 'Mutation', authChangePassword: boolean };

export type AuthResetPasswordRequestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AuthResetPasswordRequestMutation = { __typename?: 'Mutation', authResetPasswordRequest: string };

export type AuthResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
}>;


export type AuthResetPasswordMutation = { __typename?: 'Mutation', authResetPassword: { __typename?: 'LoginResponse', accessToken: string, refreshToken: string, user: { __typename?: 'UserSelf', id: string } } };

export type AuthSetUserPasswordMutationVariables = Exact<{
  userId: Scalars['String'];
  newPassword: Scalars['String'];
  repeatPassword: Scalars['String'];
}>;


export type AuthSetUserPasswordMutation = { __typename?: 'Mutation', authSetUserPassword: boolean };

export type AuthGetResetPasswordLinkQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AuthGetResetPasswordLinkQuery = { __typename?: 'Query', authGetResetPasswordLink: string };

export type NewsCategoryBaseFragment = { __typename?: 'NewsCategory', id: number, color: string, name: string };

export type NewsCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type NewsCategoriesQuery = { __typename?: 'Query', newsCategories: Array<{ __typename?: 'NewsCategory', id: number, color: string, name: string }> };

export type NewsCategoryCreateMutationVariables = Exact<{
  newsCategoryCreateInput: CreateNewsCategoryInput;
}>;


export type NewsCategoryCreateMutation = { __typename?: 'Mutation', newsCategoryCreate: { __typename?: 'NewsCategory', id: number, color: string, name: string } };

export type NewsCategoryUpdateMutationVariables = Exact<{
  newsCategoryUpdateInput: UpdateNewsCategoryInput;
}>;


export type NewsCategoryUpdateMutation = { __typename?: 'Mutation', newsCategoryUpdate: { __typename?: 'NewsCategory', id: number, color: string, name: string } };

export type NewsCategoryRemoveMutationVariables = Exact<{
  newsCategoryRemoveId: Scalars['Int'];
}>;


export type NewsCategoryRemoveMutation = { __typename?: 'Mutation', newsCategoryRemove: { __typename?: 'NewsCategory', id: number, color: string, name: string } };

export type NewsBaseFragment = { __typename?: 'News', id: number, sideboxEnabled?: boolean | null, sideboxText?: string | null, text: string, title: string, visible?: boolean | null, createdAt: any, imageUrl?: string | null, newsCategory?: { __typename?: 'NewsCategory', id: number, color: string, name: string } | null };

export type NewsListQueryVariables = Exact<{ [key: string]: never; }>;


export type NewsListQuery = { __typename?: 'Query', newsList: Array<{ __typename?: 'News', id: number, sideboxEnabled?: boolean | null, sideboxText?: string | null, text: string, title: string, visible?: boolean | null, createdAt: any, imageUrl?: string | null, newsCategory?: { __typename?: 'NewsCategory', id: number, color: string, name: string } | null }> };

export type NewsCreateMutationVariables = Exact<{
  newsCreateInput: CreateNewsInput;
}>;


export type NewsCreateMutation = { __typename?: 'Mutation', newsCreate: { __typename?: 'News', id: number, sideboxEnabled?: boolean | null, sideboxText?: string | null, text: string, title: string, visible?: boolean | null, createdAt: any, imageUrl?: string | null, newsCategory?: { __typename?: 'NewsCategory', id: number, color: string, name: string } | null } };

export type NewsUpdateMutationVariables = Exact<{
  newsUpdateInput: UpdateNewsInput;
}>;


export type NewsUpdateMutation = { __typename?: 'Mutation', newsUpdate: { __typename?: 'News', id: number, sideboxEnabled?: boolean | null, sideboxText?: string | null, text: string, title: string, visible?: boolean | null, createdAt: any, imageUrl?: string | null, newsCategory?: { __typename?: 'NewsCategory', id: number, color: string, name: string } | null } };

export type NewsRemoveMutationVariables = Exact<{
  newsRemoveId: Scalars['Int'];
}>;


export type NewsRemoveMutation = { __typename?: 'Mutation', newsRemove: { __typename?: 'News', id: number, sideboxEnabled?: boolean | null, sideboxText?: string | null, text: string, title: string, visible?: boolean | null, createdAt: any, imageUrl?: string | null, newsCategory?: { __typename?: 'NewsCategory', id: number, color: string, name: string } | null } };

export type OrganisationBaseFragment = { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, trialActivatedAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, licenseRenews: boolean, isLocked: boolean, comment?: string | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, userSessionLimit?: number | null };

export type OrgAdminOrganisationBaseFragment = { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus };

export type OrganisationUserBaseFragment = { __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isAdmin: boolean, isOrgAdmin: boolean, isActivated: boolean, status: UserStatus };

export type OrganisationProductBaseFragment = { __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean };

export type OrganisationSpecialAnalysisBaseFragment = { __typename?: 'SpecialAnalysis', id: number, name: string };

export type OrganisationUpdateOwnMutationVariables = Exact<{
  organisationEditOwnInput: OrganisationEditOwnInput;
}>;


export type OrganisationUpdateOwnMutation = { __typename?: 'Mutation', organisationUpdateOwn: { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus } };

export type OrganisationQueryVariables = Exact<{
  organisationId: Scalars['String'];
}>;


export type OrganisationQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, users: Array<{ __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isAdmin: boolean, isOrgAdmin: boolean, isActivated: boolean, status: UserStatus } | null> } };

export type UserCreateForOwnOrganisationMutationVariables = Exact<{
  userCreateForOwnOrgInput: CreateUserForOwnOrgInput;
}>;


export type UserCreateForOwnOrganisationMutation = { __typename?: 'Mutation', userCreateForOwnOrganisation: { __typename?: 'User', department?: string | null, phone?: string | null, id: string, salutation: string, firstName: string, lastName: string, email: string, isAdmin: boolean, isOrgAdmin: boolean, isActivated: boolean, status: UserStatus } };

export type OrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationsQuery = { __typename?: 'Query', organisations: Array<{ __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, trialActivatedAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, licenseRenews: boolean, isLocked: boolean, comment?: string | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, userSessionLimit?: number | null, users: Array<{ __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isAdmin: boolean, isOrgAdmin: boolean, isActivated: boolean, status: UserStatus } | null>, products: Array<{ __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean } | null>, specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string } | null> }> };

export type OrganisationCreateMutationVariables = Exact<{
  organisationCreateInput: CreateOrganisationInput;
}>;


export type OrganisationCreateMutation = { __typename?: 'Mutation', organisationCreate: { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, trialActivatedAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, licenseRenews: boolean, isLocked: boolean, comment?: string | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, userSessionLimit?: number | null, users: Array<{ __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isAdmin: boolean, isOrgAdmin: boolean, isActivated: boolean, status: UserStatus } | null>, products: Array<{ __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean } | null> } };

export type OrganisationUpdateMutationVariables = Exact<{
  organisationUpdateInput: UpdateOrganisationInput;
}>;


export type OrganisationUpdateMutation = { __typename?: 'Mutation', organisationUpdate: { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, trialActivatedAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, licenseRenews: boolean, isLocked: boolean, comment?: string | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, userSessionLimit?: number | null, users: Array<{ __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isAdmin: boolean, isOrgAdmin: boolean, isActivated: boolean, status: UserStatus } | null>, products: Array<{ __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean } | null> } };

export type OrganisationRemoveMutationVariables = Exact<{
  organisationRemoveId: Scalars['String'];
}>;


export type OrganisationRemoveMutation = { __typename?: 'Mutation', organisationRemove: { __typename?: 'Organisation', id: string } };

export type OrganisationRemoveUserMutationVariables = Exact<{
  userRemoveId: Scalars['String'];
}>;


export type OrganisationRemoveUserMutation = { __typename?: 'Mutation', userRemove: { __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isAdmin: boolean, isOrgAdmin: boolean, isActivated: boolean, status: UserStatus, organisationId: string, organisation?: { __typename?: 'Organisation', id: string, name: string, licenseExpiresAt?: any | null, products: Array<{ __typename?: 'Product', id: number, name: string } | null> } | null } };

export type OrganisationAddProductMutationVariables = Exact<{
  organisationId: Scalars['String'];
  productId: Scalars['Float'];
}>;


export type OrganisationAddProductMutation = { __typename?: 'Mutation', organisationAddProduct: { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, trialActivatedAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, licenseRenews: boolean, isLocked: boolean, comment?: string | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, userSessionLimit?: number | null, products: Array<{ __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean } | null> } };

export type OrganisationRemoveProductMutationVariables = Exact<{
  organisationId: Scalars['String'];
  productId: Scalars['Float'];
}>;


export type OrganisationRemoveProductMutation = { __typename?: 'Mutation', organisationRemoveProduct: { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, trialActivatedAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, licenseRenews: boolean, isLocked: boolean, comment?: string | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, userSessionLimit?: number | null, products: Array<{ __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean } | null> } };

export type OrganisationAddSpecialAnalysisMutationVariables = Exact<{
  organisationId: Scalars['String'];
  specialAnalysisId: Scalars['Float'];
}>;


export type OrganisationAddSpecialAnalysisMutation = { __typename?: 'Mutation', organisationAddSpecialAnalysis: { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, trialActivatedAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, licenseRenews: boolean, isLocked: boolean, comment?: string | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, userSessionLimit?: number | null, specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string } | null> } };

export type OrganisationRemoveSpecialAnalysisMutationVariables = Exact<{
  organisationId: Scalars['String'];
  specialAnalysisId: Scalars['Float'];
}>;


export type OrganisationRemoveSpecialAnalysisMutation = { __typename?: 'Mutation', organisationRemoveSpecialAnalysis: { __typename?: 'Organisation', id: string, name: string, street?: string | null, street_additional?: string | null, house_no?: string | null, zip?: string | null, city?: string | null, country?: string | null, isTrial: boolean, trialDays: number, trialExpiresAt?: any | null, trialActivatedAt?: any | null, licenseType: LicenseType, licenseExpiresAt?: any | null, licenseRenews: boolean, isLocked: boolean, comment?: string | null, userAmount?: number | null, isDomainRestrictionEnabled: boolean, status: OrganisationStatus, userSessionLimit?: number | null, specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string } | null> } };

export type ProductBaseFragment = { __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean, imageUrl?: string | null, icon?: string | null, defaultMethodologyId?: number | null, specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string, productId: number }>, organisations?: Array<{ __typename?: 'Organisation', id: string, name: string, licenseType: LicenseType }> | null, productMethodologies: Array<{ __typename?: 'ProductMethodologies', methodologyId: number } | null> };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean, imageUrl?: string | null, icon?: string | null, defaultMethodologyId?: number | null, specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string, productId: number }>, organisations?: Array<{ __typename?: 'Organisation', id: string, name: string, licenseType: LicenseType }> | null, productMethodologies: Array<{ __typename?: 'ProductMethodologies', methodologyId: number } | null> }> };

export type ProductCreateMutationVariables = Exact<{
  productCreateInput: CreateProductInput;
}>;


export type ProductCreateMutation = { __typename?: 'Mutation', productCreate: { __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean, imageUrl?: string | null, icon?: string | null, defaultMethodologyId?: number | null, specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string, productId: number }>, organisations?: Array<{ __typename?: 'Organisation', id: string, name: string, licenseType: LicenseType }> | null, productMethodologies: Array<{ __typename?: 'ProductMethodologies', methodologyId: number } | null> } };

export type ProductUpdateMutationVariables = Exact<{
  productUpdateInput: UpdateProductInput;
}>;


export type ProductUpdateMutation = { __typename?: 'Mutation', productUpdate: { __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean, imageUrl?: string | null, icon?: string | null, defaultMethodologyId?: number | null, specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string, productId: number }>, organisations?: Array<{ __typename?: 'Organisation', id: string, name: string, licenseType: LicenseType }> | null, productMethodologies: Array<{ __typename?: 'ProductMethodologies', methodologyId: number } | null> } };

export type ProductRemoveMutationVariables = Exact<{
  productRemoveId: Scalars['Int'];
}>;


export type ProductRemoveMutation = { __typename?: 'Mutation', productRemove: { __typename?: 'Product', id: number, name: string, description: string, isEnabled: boolean, imageUrl?: string | null, icon?: string | null, defaultMethodologyId?: number | null, specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string, productId: number }>, organisations?: Array<{ __typename?: 'Organisation', id: string, name: string, licenseType: LicenseType }> | null, productMethodologies: Array<{ __typename?: 'ProductMethodologies', methodologyId: number } | null> } };

export type FilterSettingsBelongToUserQueryVariables = Exact<{ [key: string]: never; }>;


export type FilterSettingsBelongToUserQuery = { __typename?: 'Query', filterSettingsBelongToUser: Array<{ __typename?: 'FilterSetting', districtId?: number | null, id: number, methodologyId?: number | null, productId: number, ranking?: string | null, stateId?: number | null, submarketId?: number | null, userId: string, year?: string | null }> };

export type FilterSettingSetMutationVariables = Exact<{
  setFilterSettingInput: SetFilterSettingInput;
}>;


export type FilterSettingSetMutation = { __typename?: 'Mutation', filterSettingSet: { __typename?: 'FilterSetting', id: number, districtId?: number | null, methodologyId?: number | null, productId: number, stateId?: number | null, userId: string, submarketId?: number | null, product: { __typename?: 'Product', name: string } } };

export type SpecialAnalysisBaseFragment = { __typename?: 'SpecialAnalysis', id: number, name: string, description: string, isEnabled: boolean, productId: number };

export type SpecialAnalysesQueryVariables = Exact<{ [key: string]: never; }>;


export type SpecialAnalysesQuery = { __typename?: 'Query', specialAnalyses: Array<{ __typename?: 'SpecialAnalysis', id: number, name: string, description: string, isEnabled: boolean, productId: number }> };

export type SpecialAnalysisQueryVariables = Exact<{
  specialAnalysisId: Scalars['Int'];
}>;


export type SpecialAnalysisQuery = { __typename?: 'Query', specialAnalysis: { __typename?: 'SpecialAnalysis', id: number, name: string, description: string, isEnabled: boolean, productId: number } };

export type SpecialAnalysisCreateMutationVariables = Exact<{
  createSpecialAnalysisInput: CreateSpecialAnalysisInput;
}>;


export type SpecialAnalysisCreateMutation = { __typename?: 'Mutation', specialAnalysisCreate: { __typename?: 'SpecialAnalysis', id: number, name: string, description: string, isEnabled: boolean, productId: number } };

export type SpecialAnalysisUpdateMutationVariables = Exact<{
  updateSpecialAnalysisInput: UpdateSpecialAnalysisInput;
}>;


export type SpecialAnalysisUpdateMutation = { __typename?: 'Mutation', specialAnalysisUpdate: { __typename?: 'SpecialAnalysis', id: number, name: string, description: string, isEnabled: boolean, productId: number } };

export type SpecialAnalysisRemoveMutationVariables = Exact<{
  specialAnalysisRemoveId: Scalars['Int'];
}>;


export type SpecialAnalysisRemoveMutation = { __typename?: 'Mutation', specialAnalysisRemove: { __typename?: 'SpecialAnalysis', id: number, name: string, description: string, isEnabled: boolean, productId: number } };

export type UserBaseFragment = { __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isActivated: boolean, isLocked: boolean, isAdmin: boolean, isOrgAdmin: boolean, organisationId: string, status: UserStatus };

export type UserSelfFullFragment = { __typename?: 'UserSelf', salutation: string, department?: string | null, email: string, firstName: string, id: string, isActivated: boolean, isAdmin: boolean, isOrgAdmin: boolean, lastName: string, phone?: string | null, claims?: Array<string> | null, accessible_datasets?: any | null, organisation?: { __typename?: 'Organisation', id: string, name: string } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'UserSelf', salutation: string, department?: string | null, email: string, firstName: string, id: string, isActivated: boolean, isAdmin: boolean, isOrgAdmin: boolean, lastName: string, phone?: string | null, claims?: Array<string> | null, accessible_datasets?: any | null, organisation?: { __typename?: 'Organisation', id: string, name: string } | null } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isActivated: boolean, isLocked: boolean, isAdmin: boolean, isOrgAdmin: boolean, organisationId: string, status: UserStatus, organisation?: { __typename?: 'Organisation', id: string, name: string, licenseExpiresAt?: any | null, products: Array<{ __typename?: 'Product', id: number, name: string } | null> } | null }> };

export type UserUpdateSelfMutationVariables = Exact<{
  userUpdateSelfInput: UpdateSelfInput;
}>;


export type UserUpdateSelfMutation = { __typename?: 'Mutation', userUpdateSelf: { __typename?: 'UserSelf', salutation: string, department?: string | null, email: string, firstName: string, id: string, isActivated: boolean, isAdmin: boolean, isOrgAdmin: boolean, lastName: string, phone?: string | null, claims?: Array<string> | null, accessible_datasets?: any | null, organisation?: { __typename?: 'Organisation', id: string, name: string } | null } };

export type UserCreateMutationVariables = Exact<{
  userCreateInput: CreateUserInput;
}>;


export type UserCreateMutation = { __typename?: 'Mutation', userCreate: { __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isActivated: boolean, isLocked: boolean, isAdmin: boolean, isOrgAdmin: boolean, organisationId: string, status: UserStatus, organisation?: { __typename?: 'Organisation', id: string, name: string, licenseExpiresAt?: any | null, products: Array<{ __typename?: 'Product', id: number, name: string } | null> } | null } };

export type UserUpdateMutationVariables = Exact<{
  userUpdateInput: UpdateUserInput;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isActivated: boolean, isLocked: boolean, isAdmin: boolean, isOrgAdmin: boolean, organisationId: string, status: UserStatus, organisation?: { __typename?: 'Organisation', id: string, name: string, licenseExpiresAt?: any | null, products: Array<{ __typename?: 'Product', id: number, name: string } | null> } | null } };

export type UserRemoveMutationVariables = Exact<{
  userRemoveId: Scalars['String'];
}>;


export type UserRemoveMutation = { __typename?: 'Mutation', userRemove: { __typename?: 'User', id: string, salutation: string, firstName: string, lastName: string, email: string, department?: string | null, phone?: string | null, isActivated: boolean, isLocked: boolean, isAdmin: boolean, isOrgAdmin: boolean, organisationId: string, status: UserStatus, organisation?: { __typename?: 'Organisation', id: string, name: string, licenseExpiresAt?: any | null, products: Array<{ __typename?: 'Product', id: number, name: string } | null> } | null } };

export type UserActivationLinkQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserActivationLinkQuery = { __typename?: 'Query', userActivationLink: string };

export type UserResendActivationEmailMutationVariables = Exact<{
  resendActivationEmailInput: ResendUserActivationEmailInput;
}>;


export type UserResendActivationEmailMutation = { __typename?: 'Mutation', userResendActivationEmail: boolean };

export const NewsCategoryBaseFragmentDoc = gql`
    fragment NewsCategoryBase on NewsCategory {
  id
  color
  name
}
    `;
export const NewsBaseFragmentDoc = gql`
    fragment NewsBase on News {
  id
  newsCategory {
    id
    color
    name
  }
  sideboxEnabled
  sideboxText
  text
  title
  visible
  createdAt
  imageUrl
}
    `;
export const OrganisationBaseFragmentDoc = gql`
    fragment OrganisationBase on Organisation {
  id
  name
  street
  street_additional
  house_no
  zip
  city
  country
  isTrial
  trialDays
  trialExpiresAt
  trialActivatedAt
  licenseType
  licenseExpiresAt
  licenseRenews
  isLocked
  comment
  userAmount
  isDomainRestrictionEnabled
  status
  userAmount
  userSessionLimit
}
    `;
export const OrgAdminOrganisationBaseFragmentDoc = gql`
    fragment OrgAdminOrganisationBase on Organisation {
  id
  name
  street
  street_additional
  house_no
  zip
  city
  country
  isTrial
  trialDays
  trialExpiresAt
  licenseType
  licenseExpiresAt
  userAmount
  isDomainRestrictionEnabled
  status
  userAmount
}
    `;
export const OrganisationUserBaseFragmentDoc = gql`
    fragment OrganisationUserBase on User {
  id
  salutation
  firstName
  lastName
  email
  department
  phone
  isAdmin
  isOrgAdmin
  isActivated
  status
}
    `;
export const OrganisationProductBaseFragmentDoc = gql`
    fragment OrganisationProductBase on Product {
  id
  name
  description
  isEnabled
}
    `;
export const OrganisationSpecialAnalysisBaseFragmentDoc = gql`
    fragment OrganisationSpecialAnalysisBase on SpecialAnalysis {
  id
  name
}
    `;
export const ProductBaseFragmentDoc = gql`
    fragment ProductBase on Product {
  id
  name
  description
  isEnabled
  imageUrl
  icon
  specialAnalyses {
    id
    name
    productId
  }
  specialAnalyses {
    id
    name
  }
  organisations {
    id
    name
    licenseType
  }
  productMethodologies {
    methodologyId
  }
  defaultMethodologyId
}
    `;
export const SpecialAnalysisBaseFragmentDoc = gql`
    fragment SpecialAnalysisBase on SpecialAnalysis {
  id
  name
  description
  isEnabled
  productId
}
    `;
export const UserBaseFragmentDoc = gql`
    fragment UserBase on User {
  id
  salutation
  firstName
  lastName
  email
  department
  phone
  isActivated
  isLocked
  isAdmin
  isOrgAdmin
  organisationId
  status
}
    `;
export const UserSelfFullFragmentDoc = gql`
    fragment UserSelfFull on UserSelf {
  salutation
  department
  email
  firstName
  id
  isActivated
  isAdmin
  isOrgAdmin
  lastName
  phone
  organisation {
    id
    name
  }
  claims
  accessible_datasets
}
    `;
export const AuthActivateUserDocument = gql`
    mutation AuthActivateUser($newPassword: String!, $token: String!) {
  authActivateUser(newPassword: $newPassword, token: $token) {
    accessToken
    refreshToken
    user {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthActivateUserGQL extends Apollo.Mutation<AuthActivateUserMutation, AuthActivateUserMutationVariables> {
    override document = AuthActivateUserDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthLoginDocument = gql`
    mutation AuthLogin($email: String!, $password: String!) {
  authLogin(email: $email, password: $password) {
    accessToken
    refreshToken
    user {
      id
      email
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthLoginGQL extends Apollo.Mutation<AuthLoginMutation, AuthLoginMutationVariables> {
    override document = AuthLoginDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthRefreshTokenDocument = gql`
    mutation AuthRefreshToken {
  authRefreshToken {
    accessToken
    refreshToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthRefreshTokenGQL extends Apollo.Mutation<AuthRefreshTokenMutation, AuthRefreshTokenMutationVariables> {
    override document = AuthRefreshTokenDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthChangePasswordDocument = gql`
    mutation AuthChangePassword($currentPassword: String!, $newPassword: String!) {
  authChangePassword(currentPassword: $currentPassword, newPassword: $newPassword)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthChangePasswordGQL extends Apollo.Mutation<AuthChangePasswordMutation, AuthChangePasswordMutationVariables> {
    override document = AuthChangePasswordDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthResetPasswordRequestDocument = gql`
    mutation AuthResetPasswordRequest($email: String!) {
  authResetPasswordRequest(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthResetPasswordRequestGQL extends Apollo.Mutation<AuthResetPasswordRequestMutation, AuthResetPasswordRequestMutationVariables> {
    override document = AuthResetPasswordRequestDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthResetPasswordDocument = gql`
    mutation AuthResetPassword($newPassword: String!, $token: String!) {
  authResetPassword(newPassword: $newPassword, token: $token) {
    accessToken
    refreshToken
    user {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthResetPasswordGQL extends Apollo.Mutation<AuthResetPasswordMutation, AuthResetPasswordMutationVariables> {
    override document = AuthResetPasswordDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthSetUserPasswordDocument = gql`
    mutation AuthSetUserPassword($userId: String!, $newPassword: String!, $repeatPassword: String!) {
  authSetUserPassword(
    userId: $userId
    newPassword: $newPassword
    repeatPassword: $repeatPassword
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthSetUserPasswordGQL extends Apollo.Mutation<AuthSetUserPasswordMutation, AuthSetUserPasswordMutationVariables> {
    override document = AuthSetUserPasswordDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthGetResetPasswordLinkDocument = gql`
    query AuthGetResetPasswordLink($userId: String!) {
  authGetResetPasswordLink(userId: $userId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthGetResetPasswordLinkGQL extends Apollo.Query<AuthGetResetPasswordLinkQuery, AuthGetResetPasswordLinkQueryVariables> {
    override document = AuthGetResetPasswordLinkDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsCategoriesDocument = gql`
    query NewsCategories {
  newsCategories {
    ...NewsCategoryBase
  }
}
    ${NewsCategoryBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsCategoriesGQL extends Apollo.Query<NewsCategoriesQuery, NewsCategoriesQueryVariables> {
    override document = NewsCategoriesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsCategoryCreateDocument = gql`
    mutation NewsCategoryCreate($newsCategoryCreateInput: CreateNewsCategoryInput!) {
  newsCategoryCreate(newsCategoryCreateInput: $newsCategoryCreateInput) {
    ...NewsCategoryBase
  }
}
    ${NewsCategoryBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsCategoryCreateGQL extends Apollo.Mutation<NewsCategoryCreateMutation, NewsCategoryCreateMutationVariables> {
    override document = NewsCategoryCreateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsCategoryUpdateDocument = gql`
    mutation NewsCategoryUpdate($newsCategoryUpdateInput: UpdateNewsCategoryInput!) {
  newsCategoryUpdate(newsCategoryUpdateInput: $newsCategoryUpdateInput) {
    ...NewsCategoryBase
  }
}
    ${NewsCategoryBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsCategoryUpdateGQL extends Apollo.Mutation<NewsCategoryUpdateMutation, NewsCategoryUpdateMutationVariables> {
    override document = NewsCategoryUpdateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsCategoryRemoveDocument = gql`
    mutation NewsCategoryRemove($newsCategoryRemoveId: Int!) {
  newsCategoryRemove(id: $newsCategoryRemoveId) {
    ...NewsCategoryBase
  }
}
    ${NewsCategoryBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsCategoryRemoveGQL extends Apollo.Mutation<NewsCategoryRemoveMutation, NewsCategoryRemoveMutationVariables> {
    override document = NewsCategoryRemoveDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsListDocument = gql`
    query NewsList {
  newsList {
    ...NewsBase
  }
}
    ${NewsBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsListGQL extends Apollo.Query<NewsListQuery, NewsListQueryVariables> {
    override document = NewsListDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsCreateDocument = gql`
    mutation NewsCreate($newsCreateInput: CreateNewsInput!) {
  newsCreate(newsCreateInput: $newsCreateInput) {
    ...NewsBase
  }
}
    ${NewsBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsCreateGQL extends Apollo.Mutation<NewsCreateMutation, NewsCreateMutationVariables> {
    override document = NewsCreateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsUpdateDocument = gql`
    mutation NewsUpdate($newsUpdateInput: UpdateNewsInput!) {
  newsUpdate(newsUpdateInput: $newsUpdateInput) {
    ...NewsBase
  }
}
    ${NewsBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsUpdateGQL extends Apollo.Mutation<NewsUpdateMutation, NewsUpdateMutationVariables> {
    override document = NewsUpdateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsRemoveDocument = gql`
    mutation NewsRemove($newsRemoveId: Int!) {
  newsRemove(id: $newsRemoveId) {
    ...NewsBase
  }
}
    ${NewsBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsRemoveGQL extends Apollo.Mutation<NewsRemoveMutation, NewsRemoveMutationVariables> {
    override document = NewsRemoveDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationUpdateOwnDocument = gql`
    mutation OrganisationUpdateOwn($organisationEditOwnInput: OrganisationEditOwnInput!) {
  organisationUpdateOwn(organisationEditOwnInput: $organisationEditOwnInput) {
    ...OrgAdminOrganisationBase
  }
}
    ${OrgAdminOrganisationBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationUpdateOwnGQL extends Apollo.Mutation<OrganisationUpdateOwnMutation, OrganisationUpdateOwnMutationVariables> {
    override document = OrganisationUpdateOwnDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationDocument = gql`
    query Organisation($organisationId: String!) {
  organisation(id: $organisationId) {
    ...OrgAdminOrganisationBase
    users {
      ...OrganisationUserBase
    }
  }
}
    ${OrgAdminOrganisationBaseFragmentDoc}
${OrganisationUserBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationGQL extends Apollo.Query<OrganisationQuery, OrganisationQueryVariables> {
    override document = OrganisationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserCreateForOwnOrganisationDocument = gql`
    mutation UserCreateForOwnOrganisation($userCreateForOwnOrgInput: CreateUserForOwnOrgInput!) {
  userCreateForOwnOrganisation(
    userCreateForOwnOrgInput: $userCreateForOwnOrgInput
  ) {
    ...OrganisationUserBase
    department
    phone
  }
}
    ${OrganisationUserBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserCreateForOwnOrganisationGQL extends Apollo.Mutation<UserCreateForOwnOrganisationMutation, UserCreateForOwnOrganisationMutationVariables> {
    override document = UserCreateForOwnOrganisationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationsDocument = gql`
    query Organisations {
  organisations {
    ...OrganisationBase
    users {
      ...OrganisationUserBase
    }
    products {
      ...OrganisationProductBase
    }
    specialAnalyses {
      ...OrganisationSpecialAnalysisBase
    }
  }
}
    ${OrganisationBaseFragmentDoc}
${OrganisationUserBaseFragmentDoc}
${OrganisationProductBaseFragmentDoc}
${OrganisationSpecialAnalysisBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationsGQL extends Apollo.Query<OrganisationsQuery, OrganisationsQueryVariables> {
    override document = OrganisationsDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationCreateDocument = gql`
    mutation OrganisationCreate($organisationCreateInput: CreateOrganisationInput!) {
  organisationCreate(organisationCreateInput: $organisationCreateInput) {
    ...OrganisationBase
    users {
      ...OrganisationUserBase
    }
    products {
      ...OrganisationProductBase
    }
  }
}
    ${OrganisationBaseFragmentDoc}
${OrganisationUserBaseFragmentDoc}
${OrganisationProductBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationCreateGQL extends Apollo.Mutation<OrganisationCreateMutation, OrganisationCreateMutationVariables> {
    override document = OrganisationCreateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationUpdateDocument = gql`
    mutation OrganisationUpdate($organisationUpdateInput: UpdateOrganisationInput!) {
  organisationUpdate(organisationUpdateInput: $organisationUpdateInput) {
    ...OrganisationBase
    users {
      ...OrganisationUserBase
    }
    products {
      ...OrganisationProductBase
    }
  }
}
    ${OrganisationBaseFragmentDoc}
${OrganisationUserBaseFragmentDoc}
${OrganisationProductBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationUpdateGQL extends Apollo.Mutation<OrganisationUpdateMutation, OrganisationUpdateMutationVariables> {
    override document = OrganisationUpdateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationRemoveDocument = gql`
    mutation OrganisationRemove($organisationRemoveId: String!) {
  organisationRemove(id: $organisationRemoveId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationRemoveGQL extends Apollo.Mutation<OrganisationRemoveMutation, OrganisationRemoveMutationVariables> {
    override document = OrganisationRemoveDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationRemoveUserDocument = gql`
    mutation OrganisationRemoveUser($userRemoveId: String!) {
  userRemove(id: $userRemoveId) {
    id
    salutation
    firstName
    lastName
    email
    department
    phone
    isAdmin
    isOrgAdmin
    isActivated
    status
    organisationId
    organisation {
      id
      name
      licenseExpiresAt
      products {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationRemoveUserGQL extends Apollo.Mutation<OrganisationRemoveUserMutation, OrganisationRemoveUserMutationVariables> {
    override document = OrganisationRemoveUserDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationAddProductDocument = gql`
    mutation OrganisationAddProduct($organisationId: String!, $productId: Float!) {
  organisationAddProduct(organisationId: $organisationId, productId: $productId) {
    ...OrganisationBase
    products {
      ...OrganisationProductBase
    }
  }
}
    ${OrganisationBaseFragmentDoc}
${OrganisationProductBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationAddProductGQL extends Apollo.Mutation<OrganisationAddProductMutation, OrganisationAddProductMutationVariables> {
    override document = OrganisationAddProductDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationRemoveProductDocument = gql`
    mutation OrganisationRemoveProduct($organisationId: String!, $productId: Float!) {
  organisationRemoveProduct(
    organisationId: $organisationId
    productId: $productId
  ) {
    ...OrganisationBase
    products {
      ...OrganisationProductBase
    }
  }
}
    ${OrganisationBaseFragmentDoc}
${OrganisationProductBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationRemoveProductGQL extends Apollo.Mutation<OrganisationRemoveProductMutation, OrganisationRemoveProductMutationVariables> {
    override document = OrganisationRemoveProductDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationAddSpecialAnalysisDocument = gql`
    mutation OrganisationAddSpecialAnalysis($organisationId: String!, $specialAnalysisId: Float!) {
  organisationAddSpecialAnalysis(
    organisationId: $organisationId
    specialAnalysisId: $specialAnalysisId
  ) {
    ...OrganisationBase
    specialAnalyses {
      ...OrganisationSpecialAnalysisBase
    }
  }
}
    ${OrganisationBaseFragmentDoc}
${OrganisationSpecialAnalysisBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationAddSpecialAnalysisGQL extends Apollo.Mutation<OrganisationAddSpecialAnalysisMutation, OrganisationAddSpecialAnalysisMutationVariables> {
    override document = OrganisationAddSpecialAnalysisDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganisationRemoveSpecialAnalysisDocument = gql`
    mutation OrganisationRemoveSpecialAnalysis($organisationId: String!, $specialAnalysisId: Float!) {
  organisationRemoveSpecialAnalysis(
    organisationId: $organisationId
    specialAnalysisId: $specialAnalysisId
  ) {
    ...OrganisationBase
    specialAnalyses {
      ...OrganisationSpecialAnalysisBase
    }
  }
}
    ${OrganisationBaseFragmentDoc}
${OrganisationSpecialAnalysisBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganisationRemoveSpecialAnalysisGQL extends Apollo.Mutation<OrganisationRemoveSpecialAnalysisMutation, OrganisationRemoveSpecialAnalysisMutationVariables> {
    override document = OrganisationRemoveSpecialAnalysisDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductsDocument = gql`
    query Products {
  products {
    ...ProductBase
  }
}
    ${ProductBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductsGQL extends Apollo.Query<ProductsQuery, ProductsQueryVariables> {
    override document = ProductsDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductCreateDocument = gql`
    mutation ProductCreate($productCreateInput: CreateProductInput!) {
  productCreate(productCreateInput: $productCreateInput) {
    ...ProductBase
  }
}
    ${ProductBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductCreateGQL extends Apollo.Mutation<ProductCreateMutation, ProductCreateMutationVariables> {
    override document = ProductCreateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductUpdateDocument = gql`
    mutation ProductUpdate($productUpdateInput: UpdateProductInput!) {
  productUpdate(productUpdateInput: $productUpdateInput) {
    ...ProductBase
  }
}
    ${ProductBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductUpdateGQL extends Apollo.Mutation<ProductUpdateMutation, ProductUpdateMutationVariables> {
    override document = ProductUpdateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductRemoveDocument = gql`
    mutation ProductRemove($productRemoveId: Int!) {
  productRemove(id: $productRemoveId) {
    ...ProductBase
  }
}
    ${ProductBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductRemoveGQL extends Apollo.Mutation<ProductRemoveMutation, ProductRemoveMutationVariables> {
    override document = ProductRemoveDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FilterSettingsBelongToUserDocument = gql`
    query FilterSettingsBelongToUser {
  filterSettingsBelongToUser {
    districtId
    id
    methodologyId
    productId
    ranking
    stateId
    submarketId
    userId
    year
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FilterSettingsBelongToUserGQL extends Apollo.Query<FilterSettingsBelongToUserQuery, FilterSettingsBelongToUserQueryVariables> {
    override document = FilterSettingsBelongToUserDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FilterSettingSetDocument = gql`
    mutation FilterSettingSet($setFilterSettingInput: SetFilterSettingInput!) {
  filterSettingSet(setFilterSettingInput: $setFilterSettingInput) {
    id
    districtId
    methodologyId
    productId
    product {
      name
    }
    stateId
    userId
    submarketId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FilterSettingSetGQL extends Apollo.Mutation<FilterSettingSetMutation, FilterSettingSetMutationVariables> {
    override document = FilterSettingSetDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SpecialAnalysesDocument = gql`
    query SpecialAnalyses {
  specialAnalyses {
    ...SpecialAnalysisBase
  }
}
    ${SpecialAnalysisBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SpecialAnalysesGQL extends Apollo.Query<SpecialAnalysesQuery, SpecialAnalysesQueryVariables> {
    override document = SpecialAnalysesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SpecialAnalysisDocument = gql`
    query SpecialAnalysis($specialAnalysisId: Int!) {
  specialAnalysis(id: $specialAnalysisId) {
    ...SpecialAnalysisBase
  }
}
    ${SpecialAnalysisBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SpecialAnalysisGQL extends Apollo.Query<SpecialAnalysisQuery, SpecialAnalysisQueryVariables> {
    override document = SpecialAnalysisDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SpecialAnalysisCreateDocument = gql`
    mutation SpecialAnalysisCreate($createSpecialAnalysisInput: CreateSpecialAnalysisInput!) {
  specialAnalysisCreate(createSpecialAnalysisInput: $createSpecialAnalysisInput) {
    ...SpecialAnalysisBase
  }
}
    ${SpecialAnalysisBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SpecialAnalysisCreateGQL extends Apollo.Mutation<SpecialAnalysisCreateMutation, SpecialAnalysisCreateMutationVariables> {
    override document = SpecialAnalysisCreateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SpecialAnalysisUpdateDocument = gql`
    mutation SpecialAnalysisUpdate($updateSpecialAnalysisInput: UpdateSpecialAnalysisInput!) {
  specialAnalysisUpdate(updateSpecialAnalysisInput: $updateSpecialAnalysisInput) {
    ...SpecialAnalysisBase
  }
}
    ${SpecialAnalysisBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SpecialAnalysisUpdateGQL extends Apollo.Mutation<SpecialAnalysisUpdateMutation, SpecialAnalysisUpdateMutationVariables> {
    override document = SpecialAnalysisUpdateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SpecialAnalysisRemoveDocument = gql`
    mutation SpecialAnalysisRemove($specialAnalysisRemoveId: Int!) {
  specialAnalysisRemove(id: $specialAnalysisRemoveId) {
    ...SpecialAnalysisBase
  }
}
    ${SpecialAnalysisBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SpecialAnalysisRemoveGQL extends Apollo.Mutation<SpecialAnalysisRemoveMutation, SpecialAnalysisRemoveMutationVariables> {
    override document = SpecialAnalysisRemoveDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query Me {
  me {
    ...UserSelfFull
  }
}
    ${UserSelfFullFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query Users {
  users {
    ...UserBase
    organisation {
      id
      name
      licenseExpiresAt
      products {
        id
        name
      }
    }
  }
}
    ${UserBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    override document = UsersDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserUpdateSelfDocument = gql`
    mutation UserUpdateSelf($userUpdateSelfInput: UpdateSelfInput!) {
  userUpdateSelf(userUpdateSelfInput: $userUpdateSelfInput) {
    ...UserSelfFull
  }
}
    ${UserSelfFullFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateSelfGQL extends Apollo.Mutation<UserUpdateSelfMutation, UserUpdateSelfMutationVariables> {
    override document = UserUpdateSelfDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserCreateDocument = gql`
    mutation UserCreate($userCreateInput: CreateUserInput!) {
  userCreate(userCreateInput: $userCreateInput) {
    ...UserBase
    organisation {
      id
      name
      licenseExpiresAt
      products {
        id
        name
      }
    }
  }
}
    ${UserBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserCreateGQL extends Apollo.Mutation<UserCreateMutation, UserCreateMutationVariables> {
    override document = UserCreateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserUpdateDocument = gql`
    mutation UserUpdate($userUpdateInput: UpdateUserInput!) {
  userUpdate(userUpdateInput: $userUpdateInput) {
    ...UserBase
    organisation {
      id
      name
      licenseExpiresAt
      products {
        id
        name
      }
    }
  }
}
    ${UserBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateGQL extends Apollo.Mutation<UserUpdateMutation, UserUpdateMutationVariables> {
    override document = UserUpdateDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserRemoveDocument = gql`
    mutation UserRemove($userRemoveId: String!) {
  userRemove(id: $userRemoveId) {
    ...UserBase
    organisation {
      id
      name
      licenseExpiresAt
      products {
        id
        name
      }
    }
  }
}
    ${UserBaseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserRemoveGQL extends Apollo.Mutation<UserRemoveMutation, UserRemoveMutationVariables> {
    override document = UserRemoveDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserActivationLinkDocument = gql`
    query userActivationLink($userId: String!) {
  userActivationLink(userId: $userId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserActivationLinkGQL extends Apollo.Query<UserActivationLinkQuery, UserActivationLinkQueryVariables> {
    override document = UserActivationLinkDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserResendActivationEmailDocument = gql`
    mutation UserResendActivationEmail($resendActivationEmailInput: ResendUserActivationEmailInput!) {
  userResendActivationEmail(
    resendActivationEmailInput: $resendActivationEmailInput
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserResendActivationEmailGQL extends Apollo.Mutation<UserResendActivationEmailMutation, UserResendActivationEmailMutationVariables> {
    override document = UserResendActivationEmailDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }