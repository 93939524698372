import { MenuItem } from "../../layout/app.menu.items";


function _getProductDashboardLink(productId: number, dashboardId: string): string[] {
  return [`/dashboards/${productId.toString()}/${dashboardId}`];
}

function _getStandortdossierLink(productId: number, dashboardId: string, subDashboardName: string): string[] {
  return [`/dashboards/${productId.toString()}/${dashboardId}/${subDashboardName}`];
}


function _getSharedAnalysisMenuItems(productId: number): MenuItem[] {
  return [
    {
      label: 'Übersicht',
      icon: 'fa-solid fa-table',
      routerLink: _getProductDashboardLink(productId, '0x0x3')
    },

    // ----------- TAXED -------------

    {
      label: 'Unternehmen', // When renaming this, also rename in setActiveMainRankings() in chart-personal-page.component.ts
      icon: 'fa-solid fa-building',
      items: [
        {
          label: 'Standortdossier',
          items: [
            {
              label: 'Info',
              routerLink: _getStandortdossierLink(productId, '1x1x3', 'info')
            },
            {
              label: 'Übersicht',
              routerLink: _getStandortdossierLink(productId, '1x1x3', 'übersicht')
            },
            {
              label: 'Märkte',
              routerLink: _getStandortdossierLink(productId, '1x1x3', 'märkte')
            },
            // {
            //   label: 'Performance',
            //   routerLink: _getStandortdossierLink(productId, '1x1x3', 'performance')
            // },
            {
              label: 'Entwicklung',
              routerLink: _getStandortdossierLink(productId, '1x1x3', 'entwicklung')
            }
          ]
        },
        {
          label: 'Zeitreihen',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '2x1x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '2x1x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '2x1x12')
            }
          ]
        },
        {
          label: 'Rankings',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '3x1x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '3x1x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '3x1x12')
            },
          ]
        },
        {
          label: 'Vergleichsanalysen',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '4x1x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '4x1x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '4x1x12')
            },
            {
              label: 'Branchen',
              routerLink: _getProductDashboardLink(productId, '4x1x10')
            },
          ]
        },
        {
          label: 'Prognosen',
          visible: productId == 1,
          items: [
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '5x1x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '5x1x12')
            },
          ]
        }
      ]
    },


    // ----------- TURNOVER -------------

    {
      label: 'Umsätze', // When renaming this, also rename in setActiveMainRankings() in chart-personal-page.component.ts
      icon: 'fa-solid fa-coins',
      items: [
        {
          label: 'Standortdossier',
          items: [
            {
              label: 'Info',
              routerLink: _getStandortdossierLink(productId, '1x2x3', 'info')
            },
            {
              label: 'Übersicht',
              routerLink: _getStandortdossierLink(productId, '1x2x3', 'übersicht')
            },
            {
              label: 'Märkte',
              routerLink: _getStandortdossierLink(productId, '1x2x3', 'märkte')
            },
            // {
            //   label: 'Performance',
            //   routerLink: _getStandortdossierLink(productId, '1x2x3', 'performance')
            // },
            {
              label: 'Entwicklung',
              routerLink: _getStandortdossierLink(productId, '1x2x3', 'entwicklung')
            }
          ]
        },
        {
          label: 'Zeitreihen',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '2x2x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '2x2x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '2x2x12')
            }
          ]
        },
        {
          label: 'Rankings',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '3x2x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '3x2x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '3x2x12')
            },
          ]
        },
        {
          label: 'Vergleichsanalyse',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '4x2x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '4x2x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '4x2x12')
            },
            {
              label: 'Branchen',
              routerLink: _getProductDashboardLink(productId, '4x2x10')
            },
          ]
        },
        {
          label: 'Indexanalysen',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '7x2x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '7x2x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '7x2x12')
            },
          ]
        },
        {
          label: 'Prognosen',
          visible: productId == 1,
          items: [
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '5x2x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '5x2x12')
            },
          ]
        },
      ]
    },


    // ----------- EMPLOYED -------------

    {
      label: 'Beschäftigte', // When renaming this, also rename in setActiveMainRankings() in chart-personal-page.component.ts
      icon: 'fa-solid fa-users',
      items: [
        {
          label: 'Standortdossier',
          items: [
            {
              label: 'Info',
              routerLink: _getStandortdossierLink(productId, '1x5x3', 'info')
            },
            {
              label: 'Übersicht',
              routerLink: _getStandortdossierLink(productId, '1x5x3', 'übersicht')
            },
            {
              label: 'Märkte',
              routerLink: _getStandortdossierLink(productId, '1x5x3', 'märkte')
            },
            // {
            //   label: 'Performance',
            //   routerLink: _getStandortdossierLink(productId, '1x5x3', 'performance')
            // },
            {
              label: 'Entwicklung',
              routerLink: _getStandortdossierLink(productId, '1x5x3', 'entwicklung')
            }
          ]
        },
        {
          label: 'Zeitreihen',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '2x5x3')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '2x5x4')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '2x5x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '2x5x12')
            }
          ]
        },
        {
          label: 'Rankings',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '3x5x3')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '3x5x4')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '3x5x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '3x5x12')
            },
          ]
        },
        {
          label: 'Vergleichsanalyse',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '4x5x3')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '4x5x4')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '4x5x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '4x5x12')
            },
            {
              label: 'Branchen',
              routerLink: _getProductDashboardLink(productId, '4x5x10')
            },
          ]
        },
        {
          label: 'Indexanalysen',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '7x5x3')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '7x5x4')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '7x5x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '7x5x12')
            },
          ]
        },
        {
          label: 'Prognosen',
          visible: productId == 1,
          items: [
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '5x5x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '5x5x12')
            },
          ]
        },
        {
          label: 'Geschlechterverteilung',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '6x5x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '6x5x11')
            }
          ]
        }
      ]
    },


    // ----------- SOLO SELF EMPLOYED -------------

    {
      label: 'Solo-Selbstständige', // When renaming this, also rename in setActiveMainRankings() in chart-personal-page.component.ts
      icon: 'fa-solid fa-user',
      visible: productId == 1,
      items: [
        {
          label: 'Zeitreihen',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '2x7x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '2x7x11')
            },
          ]
        },
        {
          label: 'Rankings',
          items: [
            {
              label: 'Bundesländer',
              routerLink: _getProductDashboardLink(productId, '3x7x3')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '3x7x11')
            },
          ]
        },
      ]
    },
  ];
}

function _getSpecialAnalysisMenuItems(productId: number): MenuItem[] {
  const specialAnalysisById = new Map([
    [1, [
      {
        label: 'Gameswirtschaft ⌛',
        icon: 'fa-solid fa-gamepad',
        requiredClaims: ['access_special_analysis_2'],
        hideWhenNoItems: true,
        items: [
          {
            label: 'Unternehmen/Institutionen',
            items: [
              {
                label: 'Mapping',
                routerLink: _getProductDashboardLink(productId, '9x11x91')
              },
              {
                label: 'Verteilung',
                routerLink: _getProductDashboardLink(productId, '9x11x93')
              },
              {
                label: 'Zeitverlauf',
                routerLink: _getProductDashboardLink(productId, '9x11x94')
              },
            ]
          },
          {
            label: 'Beschäftigte',
            items: [
              {
                label: 'Zeitverlauf',
                routerLink: _getProductDashboardLink(productId, '9x5x94')
              },
            ]
          },
        ]
      },
      {
        label: 'BaWü Regional',
        icon: 'fa-solid fa-location-dot',
        requiredClaims: ['access_special_analysis_1'],
        hideWhenNoItems: true,
        items: _getBaWuRegionalMenuItems(productId)
      },
      {
        label: 'Bayern Regional',
        icon: 'fa-solid fa-location-dot',
        requiredClaims: ['access_special_analysis_4'],
        hideWhenNoItems: true,
        items: _getBavariaRegionalMenuItems(productId)
      }
    ]],
    [4, [
      {
        label: 'BaWü Regional',
        icon: 'fa-solid fa-location-dot',
        requiredClaims: ['access_special_analysis_5'],
        hideWhenNoItems: true,
        items: _getBaWuRegionalMenuItems(productId)
      },
      {
        label: 'Bayern Regional',
        icon: 'fa-solid fa-location-dot',
        requiredClaims: ['access_special_analysis_3'],
        hideWhenNoItems: true,
        items: _getBavariaRegionalMenuItems(productId)
      }
    ]],
    [5, [
      {
        label: 'Bayern Regional',
        icon: 'fa-solid fa-location-dot',
        requiredClaims: ['access_special_analysis_6'],
        hideWhenNoItems: true,
        items: _getBavariaRegionalMenuItems(productId)
      }
    ]]
  ])

  return specialAnalysisById.get(productId) || [];
}

function _getBaWuRegionalMenuItems(productId: number): MenuItem[] {
  return [
    {
      label: 'Unternehmen',
      items: [
        {
          label: 'Zeitreihen',
          items: [
            {
              label: 'Regierungsbezirke',
              routerLink: _getProductDashboardLink(productId, '8x2x1x6')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '8x2x1x4')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '8x2x1x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '8x2x1x12')
            }
          ]
        },
        {
          label: 'Teilmärkte',
          routerLink: _getProductDashboardLink(productId, '8x1x11')
        },
        {
          label: 'WZ-Klassen',
          routerLink: _getProductDashboardLink(productId, '8x1x12')
        },
        {
          label: 'Mapping & Ranking',
          routerLink: _getProductDashboardLink(productId, '8x1x92')
        },
        {
          label: 'Vergleichsanalyse',
          routerLink: _getProductDashboardLink(productId, '8x1x95')
        }
      ]
    },
    {
      label: 'Umsätze',
      items: [
        {
          label: 'Zeitreihen',
          items: [
            {
              label: 'Regierungsbezirke',
              routerLink: _getProductDashboardLink(productId, '8x2x2x6')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '8x2x2x4')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '8x2x2x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '8x2x2x12')
            }
          ]
        },
        {
          label: 'Teilmärkte',
          routerLink: _getProductDashboardLink(productId, '8x2x11')
        },
        {
          label: 'WZ-Klassen',
          routerLink: _getProductDashboardLink(productId, '8x2x12')
        },
        {
          label: 'Mapping & Ranking',
          routerLink: _getProductDashboardLink(productId, '8x2x92')
        },
        {
          label: 'Vergleichsanalyse',
          routerLink: _getProductDashboardLink(productId, '8x2x95')
        },
        {
          label: 'Indexanalysen',
          items: [
            {
              label: 'Regierungsbezirke',
              routerLink: _getProductDashboardLink(productId, '8x2x6')
            },
            {
              label: 'Regionalverbände',
              routerLink: _getProductDashboardLink(productId, '8x2x9')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '8x2x8')
            },
          ]
        },
      ]
    },
  ]
}

function _getBavariaRegionalMenuItems(productId: number): MenuItem[] {
  return [
    {
      label: 'Unternehmen',
      items: [
        {
          label: 'Zeitreihen',
          items: [
            {
              label: 'Regierungsbezirke',
              routerLink: _getProductDashboardLink(productId, '10x2x1x6')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '10x2x1x4')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '10x2x1x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '10x2x1x12')
            }
          ]
        },
        {
          label: 'Teilmärkte',
          routerLink: _getProductDashboardLink(productId, '10x1x11')
        },
        {
          label: 'WZ-Klassen',
          routerLink: _getProductDashboardLink(productId, '10x1x12')
        },
        {
          label: 'Mapping & Ranking',
          routerLink: _getProductDashboardLink(productId, '10x1x92')
        },
        {
          label: 'Vergleichsanalyse',
          routerLink: _getProductDashboardLink(productId, '10x1x95')
        }
      ]
    },
    {
      label: 'Umsätze',
      items: [
        {
          label: 'Zeitreihen',
          items: [
            {
              label: 'Regierungsbezirke',
              routerLink: _getProductDashboardLink(productId, '10x2x2x6')
            },
            {
              label: 'Kreise & kreisfr. Städte',
              routerLink: _getProductDashboardLink(productId, '10x2x2x4')
            },
            {
              label: 'Teilmärkte',
              routerLink: _getProductDashboardLink(productId, '10x2x2x11')
            },
            {
              label: 'WZ-Klassen',
              routerLink: _getProductDashboardLink(productId, '10x2x2x12')
            }
          ]
        },
        {
          label: 'Teilmärkte',
          routerLink: _getProductDashboardLink(productId, '10x2x11')
        },
        {
          label: 'WZ-Klassen',
          routerLink: _getProductDashboardLink(productId, '10x2x12')
        },
        {
          label: 'Mapping & Ranking',
          routerLink: _getProductDashboardLink(productId, '10x2x92')
        },
        {
          label: 'Vergleichsanalyse',
          routerLink: _getProductDashboardLink(productId, '10x2x95')
        },
        {
          label: 'Indexanalysen',
          items: [
            {
              label: 'Regierungsbezirke',
              routerLink: _getProductDashboardLink(productId, '10x2x9')
            },
            {
              label: 'Land-/Stadtkreise',
              routerLink: _getProductDashboardLink(productId, '10x2x8')
            },
          ]
        },
      ]
    },
  ]
}

function _getProductMenuItems(productId: number, analysisMenuItems: MenuItem[], specialAnalysisMenuItems: MenuItem[]): MenuItem[] {
  return [
    {
      label: 'Analysen',
      icon: 'pi pi-th-large',
      items: analysisMenuItems
    },
    {
      label: 'Sonderanalysen',
      icon: 'pi pi-th-large',
      hideWhenNoItems: true,
      items: specialAnalysisMenuItems
    },
    {
      label: 'Methodik',
      items: [
        {
          label: 'Datenquellen',
          icon: 'pi pi-info-circle',
          routerLink: _getProductDashboardLink(productId, 'methodology-data-source')
        },
        {
          label: 'Datenverarbeitung',
          icon: 'pi pi-chart-bar',
          routerLink: _getProductDashboardLink(productId, 'methodology-data-processing')
        },
        {
          label: 'Erfassungssystematiken',
          icon: 'pi pi-box',
          routerLink: _getProductDashboardLink(productId, 'methodology-table')
        },
        {
          label: 'Methodik-Vergleich',
          icon: 'pi pi-th-large',
          routerLink: _getProductDashboardLink(productId, 'methodology-table-comparison')
        },
      ]
    }
  ] as MenuItem[]
}


export const productMenuItems = new Map<number, MenuItem[]>([
  [1, _getProductMenuItems(1, _getSharedAnalysisMenuItems(1), _getSpecialAnalysisMenuItems(1))],
  [2, _getProductMenuItems(2, _getSharedAnalysisMenuItems(2), [])],
  [3, _getProductMenuItems(3, _getSharedAnalysisMenuItems(3), [])],
  [4, _getProductMenuItems(4, _getSharedAnalysisMenuItems(4), _getSpecialAnalysisMenuItems(4))],
  [5, _getProductMenuItems(5, _getSharedAnalysisMenuItems(5), _getSpecialAnalysisMenuItems(5))],
]);
